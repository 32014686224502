<template lang='pug'>
algorithm-info(v-if="algorithmInfo" :info = "algorithmInfo")
pagination(v-if="pages > 1", :current-page="page", :pages="pages")
</template>

<script>
import AlgorithmInfo from '../components/tables/AlgorithmInfo'
import Pagination from '../components/Pagination'
import { getAlgorithm } from '../assets/scripts/API'
import { addMeta } from '../assets/scripts/add-meta'
import { useI18n } from 'vue-i18n'

export default {
  name: 'AlgorithmPage',
  components: { AlgorithmInfo, Pagination },
  data () {
    return {
      algorithmInfo: null,
      pages: 1,
      page: 1,
      name: '',
      coins: []
    }
  },
  computed: {
    coinsForDescription () {
      const array = []
      for (let i = 0; i < this.coins.length && i < 5; i++) {
        array.push(this.coins[i].symbol)
      }
      return array.join(', ')
    }
  },
  methods: {
    async getDataPage (slug, page = 1) {
      const data = await getAlgorithm(slug, page)
      if (data.status === 'error') return await this.$router.push({ name: 'Error' })
      this.algorithmInfo = data
      this.pages = data.pages
      this.page = data.page
      this.name = data.algorithm_name
      this.coins = data.coins
    }
  },
  async mounted () {
    await this.getDataPage(this.$route.params.slug)
    addMeta(
      this.t('title', [this.name]),
      this.t('description', [this.name, this.coinsForDescription])
    )
  },
  async beforeRouteEnter (to, from, next) {
    await getAlgorithm(to.params.slug)
    next()
  },
  async beforeRouteUpdate (to, from, next) {
    this.page = +to.query.page
    await this.getDataPage(to.params.slug, this.page)
    next()
  },
  watch: {
    '$i18n.locale' () {
      addMeta(
        this.t('title', [this.name]),
        this.t('description', [this.name, this.coinsForDescription])
      )
    }
  },
  setup () {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'local',
      messages: {
        ru: {
          title: 'Криптовалюты, поддерживающие {0} алгоритм',
          description: 'Попуряные криптовалюты, работающие на {0} алгоритме: {1}'
        },
        en: {
          title: 'Cryptocurrencies supporting {0} algorithm',
          description: 'Popular cryptocurrencies running on the {0} algorithm: {'
        },
        es: {
          title: 'Criptomonedas compatibles con el algoritmo {0}',
          description: 'Criptomonedas populares que se ejecutan en el algoritmo {0}: {1}'
        },
        pt: {
          title: 'Criptomoedas que suportam o algoritmo {0}',
          description: 'Criptomoedas populares em execução no algoritmo {0}: {1}'
        },
        de: {
          title: 'Kryptowährungen, die den {0}-Algorithmus unterstützen',
          description: 'Beliebte Kryptowährungen, die auf dem {0}-Algorithmus laufen: {1}'
        },
        fr: {
          title: 'Crypto-monnaies prenant en charge l\'algorithme {0}',
          description: 'Crypto-monnaies populaires exécutées sur l\'algorithme {0} : {1}'
        },
        it: {
          title: 'Criptovalute che supportano l\'algoritmo {0}',
          description: 'Criptovalute popolari eseguite con l\'algoritmo {0}: {1}'
        },
        pl: {
          title: 'Kryptowaluty obsługujące algorytm {0}',
          description: 'Popularne kryptowaluty działające na algorytmie {0}: {1}'
        },
        ch: {
          title: '支持 {0} 算法的加密貨幣',
          description: '在 {0} 算法上運行的流行加密貨幣：{1}'
        },
        jp: {
          title: '{0}アルゴリズムをサポートする暗号通貨',
          description: '{0}アルゴリズムで実行されている人気のある暗号通貨：{1}'
        }
      }
    })
    return { t }
  }
}
</script>
